<template>
    <div>
        <ClientOnly>
            <VJoyDialog
                id="gdprDialog"
                ref="gdprDialog"
                pattern-confirm
                :confirm-text="$t('gdpr.acceptance.popup.cta.label')"
                :focus-trap="false"
                @dialog:confirm="acceptGdpr"
            >
                <template #dialog-header>
                    <MaltImg height="160" width="175" class="banner-img" src="/legal/legal-acceptance-illus" alt="" />
                    <p>{{ $t('gdpr.acceptance.popup.title') }}</p>
                </template>
                <template #dialog-body>
                    <!-- eslint-disable-next-line vue/no-v-html -->
                    <div v-html="$t('gdpr.acceptance.popup.message', [''])" />
                </template>
            </VJoyDialog>
        </ClientOnly>
    </div>
</template>

<script setup lang="ts">
    import {MaltImg} from '#components';
    import {storeToRefs} from 'pinia';
    import {ref} from 'vue';
    import {onMounted, useAuth, useContent, useFeatureFlag, useLocale, useWebPreferences} from '#imports';
    import {VJoyDialog} from '@maltjoy/core-vue';

    const ISO_DATE_RE = /^\d{4}-\d{2}-\d{2}$/;
    const GDPR_UPDATE_ACKNOWLEDGEMENT_ID = 'gdpr-version-acceptance-acknowledgement';
    const LAST_GDPR_VERSION_HUMAND_ID = 'policy';
    const LAST_GDPR_VERSION_TYPE = 'privacy';
    const {locale} = useLocale();

    const shouldDisplayModal = ref(false);
    const featureGdprEnabled = ref(false);
    const gdprDialog = ref();
    const auth = useAuth();
    const {loggedIn} = storeToRefs(auth);

    // Should only be used for tests
    const props = defineProps({
        forceLoggedIn: {
            type: Boolean,
            default: false,
        },
    });

    onMounted(() => {
        checkIfGdprShouldBeDisplayed();
    });

    async function checkIfGdprShouldBeDisplayed() {
        if (loggedIn.value || props.forceLoggedIn) {
            featureGdprEnabled.value = await useFeatureFlag().isFeatureEnabledForHost('freelancer-gdpr-modal-on-update');

            if (featureGdprEnabled.value) {
                const gdprVersion = await getGdprVersionAcceptedByCurrentUser();
                const lastGdprVersion = await getLastAvailableGdprVersion();

                shouldDisplayModal.value = shouldAskForAcceptanceOfLatestTc(gdprVersion || '', lastGdprVersion || '');

                if (shouldDisplayModal.value) {
                    gdprDialog.value?.show();
                }
            }
        }
    }

    function acceptGdpr() {
        useWebPreferences().setWebPref(GDPR_UPDATE_ACKNOWLEDGEMENT_ID, new Date().toISOString().substring(0, 10));
        gdprDialog.value?.hide();
    }

    function getGdprVersionAcceptedByCurrentUser() {
        return useWebPreferences().getWebPref(GDPR_UPDATE_ACKNOWLEDGEMENT_ID);
    }

    function getLastAvailableGdprVersion() {
        return useContent().getLastVersionForContent(LAST_GDPR_VERSION_HUMAND_ID, locale.value!, LAST_GDPR_VERSION_TYPE);
    }

    function shouldAskForAcceptanceOfLatestTc(tcVersion: string, lastTcVersion: string) {
        if (lastTcVersion === '') {
            lastTcVersion = new Date().toISOString().substring(0, 10);
        }

        if (tcVersion === '') {
            return true;
        }

        return isFirstIsoTextDateBeforeSecondIsoTextDate(tcVersion, lastTcVersion);
    }

    function isFirstIsoTextDateBeforeSecondIsoTextDate(dateAsStringCheckedForBeingBefore: string, referenceDateAsString: string) {
        const dateCheckedForBeingBefore = toDateIfValidIsoDate(dateAsStringCheckedForBeingBefore);
        const referenceDate = toDateIfValidIsoDate(referenceDateAsString);
        return dateCheckedForBeingBefore < referenceDate;
    }

    function toDateIfValidIsoDate(dateAsString: string) {
        if (!ISO_DATE_RE.test(dateAsString)) {
            throw new Error(`Invalid ISO date: ${dateAsString}`);
        }

        return new Date(dateAsString);
    }
</script>

<style lang="scss" scoped>
    .banner-img {
        display: block;
        margin: 0 auto var(--joy-core-spacing-4);
    }
</style>
